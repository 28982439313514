import { TileImage } from './TileImage'

export class Tile {
  tileImage: TileImage
  posX: number
  posY: number
  x: number
  y: number
  z: number
  w: number
  h: number
  centerX: number
  centerY: number
  canBeOverflown: boolean

  constructor (tileImage: TileImage, posX: number,
    posY: number,
    x: number,
    y: number,
    w: number,
    h: number,
    z: number = 0, canBeOverflown = false) {
    if (!tileImage || !tileImage.image) {
      throw Error('Попытка создать тайл без изображения')
    }

    this.tileImage = tileImage
    this.posX = posX
    this.posY = posY
    this.x = x
    this.y = y
    this.z = z
    this.w = w
    this.h = h
    this.centerX = x + w * 0.5
    this.centerY = y + h * 0.5
    this.canBeOverflown = canBeOverflown
  }

  isInCell (x: number, y: number) {
    const dx = Math.abs(x - this.centerX)
    const dy = Math.abs(y - this.centerY)

    return (dx / (this.w * 0.5) + dy / (this.h * 0.5) <= 1)
  }
}
