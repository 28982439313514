import React from 'react'
import styles from './Progressbar.module.scss'

export default class Progressbar extends React.Component<{
    value: number,
    width: string,
    height: string
}, {}> {
  render () {
    return <div className={styles.progressbar} style={{ width: this.props.width, height: this.props.height }}>
            <div style={{ width: `${this.props.value}%` }} className={styles.progress}/>
        </div>
  }
}
