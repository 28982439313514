import React from 'react'
import styles from './Dropdown.module.scss'

export default class Dropdown extends React.Component<{
    children: React.ReactNode,
    text: string
}, {
    opened: boolean
}> {
  button: React.RefObject<HTMLDivElement>
  content: React.RefObject<HTMLDivElement>
  arrowRef: React.RefObject<HTMLDivElement>

  constructor (props: { children: React.ReactNode; text: string } | Readonly<{ children: React.ReactNode; text: string }>) {
    super(props)

    this.button = React.createRef()
    this.content = React.createRef()
    this.arrowRef = React.createRef()

    this.state = {
      opened: false
    }
  }

  toggleDropdown () {
    this.setState({
      opened: !this.state.opened
    })

    if (this.content.current?.style.maxHeight) {
      // @ts-ignore
      this.content.current.style.maxHeight = null
    } else {
      // @ts-ignore
      this.content.current.style.maxHeight = this.content.current?.scrollHeight + 'px'
    }
  }

  render () {
    return <div>
            <div ref={this.button} onClick={() => this.toggleDropdown()}
                 className={`${styles.dropdown}  ${this.state.opened ? styles.dropdownActive : ''}`}>
                <div className={styles.arrow} ref={this.arrowRef}>
                    <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11.0785 13.7327C10.5792 13.7327 10.0799 13.4777 9.70539 13.2228L0.468106 3.79009C-0.156035 3.02528 -0.156035 1.75059 0.468106 0.98578C1.09225 0.220967 2.34053 0.220967 3.0895 0.98578L10.9537 9.01632L18.8179 0.98578C19.5668 0.220967 20.6903 0.220967 21.4392 0.98578C22.1882 1.75059 22.1882 2.89781 21.4392 3.66263L12.3268 13.0953C12.0771 13.4777 11.5778 13.7327 11.0785 13.7327Z"
                            fill="currentColor"/>
                    </svg>
                </div>

                <div className={styles.text}>{this.props.text}</div>
            </div>
            <div ref={this.content} className={styles.content}>
                {this.props.children}
            </div>
        </div>
  }
}
