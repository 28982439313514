import React from 'react'

export default class MusicPlayer extends React.Component<{
    style: React.CSSProperties,
}, {
    isPlaying: boolean
}> {
  musicRef: React.RefObject<HTMLAudioElement>

  constructor (props: any) {
    super(props)

    this.musicRef = React.createRef()
    this.state = {
      isPlaying: false
    }
  }

  toggleMusic () {
    this.setState({
      isPlaying: !this.state.isPlaying
    })

    if (this.musicRef.current?.paused) {
      this.musicRef.current?.play()
      return
    }

    this.musicRef.current?.pause()
  }

  render () {
    return <div onClick={() => this.toggleMusic()} style={this.props.style} className={`buttonRoundWhiteSmall buttonSound ${this.state.isPlaying ? '' : 'buttonRoundWhiteSmallDisabled'}`}>
        <audio loop={true} ref={this.musicRef} src={'/sounds/background_music.mp3'} />
        <svg width="100%" height="100%" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.5 0C10.0545 0 0 10.1158 0 22.5C0 34.8842 10.1158 45 22.5 45C34.9455 45 45 34.8842 45 22.5C45 10.1158 34.9455 0 22.5 0ZM22.5 41.9959C11.7711 41.9959 3.0654 33.2902 3.0654 22.5613C3.0654 11.8324 11.7711 3.0654 22.5 3.0654C33.2289 3.0654 41.9346 11.7711 41.9346 22.5C41.9346 33.2289 33.2289 41.9959 22.5 41.9959ZM28.2629 17.4728C27.6499 16.8597 26.6689 16.8597 26.1172 17.4728C25.5654 18.0858 25.5041 19.0668 26.1172 19.6185C27.8338 21.3351 27.8338 24.2166 26.1172 25.9332C25.5041 26.5463 25.5041 27.5272 26.1172 28.079C26.4237 28.3856 26.7916 28.5082 27.2207 28.5082C27.6499 28.5082 28.0177 28.3856 28.3242 28.079C31.2057 25.1975 31.2057 20.4155 28.2629 17.4728ZM21.703 14.5913C20.5382 13.9169 19.1281 13.9782 18.0245 14.6526L14.9591 16.4918H11.8324C9.99319 16.4918 8.46049 18.0245 8.46049 19.8638V25.7493C8.46049 27.5886 9.99319 29.1213 11.8324 29.1213H14.9591L18.0245 30.9605C18.6376 31.3283 19.2507 31.5123 19.9251 31.5123C20.5381 31.5123 21.1512 31.3283 21.7643 31.0218C22.9292 30.3474 23.6035 29.1826 23.6035 27.8338V17.7793C23.6035 16.4305 22.8679 15.2044 21.703 14.5913ZM13.8556 25.9946H11.8324C11.6485 25.9946 11.5259 25.8719 11.5259 25.688V19.8025C11.5259 19.6185 11.6485 19.4959 11.8324 19.4959H13.7943V25.9946H13.8556ZM20.5381 27.7725C20.5381 28.079 20.3542 28.2629 20.2316 28.3243C20.109 28.3856 19.8638 28.4469 19.6185 28.3243L16.921 26.6689V18.8828L19.6185 17.2275C19.8638 17.0436 20.109 17.1662 20.2316 17.2275C20.3542 17.2888 20.5381 17.4728 20.5381 17.7793V27.7725ZM30.1635 13.3651C29.5504 13.9782 29.5504 14.9591 30.1635 15.5109C34.1485 19.4959 34.1485 25.9946 30.1635 29.9796C29.5504 30.5926 29.5504 31.5736 30.1635 32.1253C30.47 32.4319 30.8379 32.5545 31.267 32.5545C31.6962 32.5545 32.064 32.4319 32.3706 32.1253C37.5817 26.9142 37.5817 18.515 32.3706 13.3038C31.7575 12.752 30.7766 12.752 30.1635 13.3651Z"
                fill="#D4172A"/>
        </svg>
    </div>
  }
}
