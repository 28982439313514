import React from 'react'
import styles from './MenuButtonRed.module.scss'

export default class MenuButtonRed extends React.Component<{
  children: React.ReactNode,
  width: string,
  height: string,
  onClick?: () => void
}, {
}> {
  render () {
    return <div onClick={this.props.onClick} style={{ width: this.props.width, height: this.props.height }} className={styles.MenuButtonRed}>
      {this.props.children}
    </div>
  }
}
