import React from 'react'
import styles from './RotateScreen.module.scss'

export default class RotateScreen extends React.Component<{
    style?: React.CSSProperties
}, {
    show: boolean
}> {
  constructor (props: any) {
    super(props)

    this.state = {
      show: false
    }
  }

  componentDidMount () {
    this.checkScreenAspectRatio()
    window.addEventListener('resize', this.checkScreenAspectRatio.bind(this))
    window.addEventListener('orientationchange', this.checkScreenAspectRatio.bind(this))
  }

  checkScreenAspectRatio () {
    if (window.innerHeight > window.innerWidth) {
      this.setState({
        show: true
      })
    } else {
      this.setState({
        show: false
      })
    }
  }

  render () {
    return this.state.show
      ? <div className={styles.rotate} style={this.props.style} >
            <img className={styles.image} src={'/phone_rotate.svg'} alt={''} />
        </div>
      : <div />
  }
}
