import React from 'react'

export default class SoundsLoader extends React.Component<{}, {
    soundsPath: Array<Object>
}> {
  buildRef: React.RefObject<HTMLAudioElement>
  chooseBuildingRef: React.RefObject<HTMLAudioElement>
  buttonClickRef: React.RefObject<HTMLAudioElement>

  constructor (props: any) {
    super(props)

    this.buildRef = React.createRef()
    this.chooseBuildingRef = React.createRef()
    this.buttonClickRef = React.createRef()

    document.addEventListener('play_button_sound', () => this.playButtonSound())
  }

  playBuildSound () {
    this.buildRef.current?.play()
  }

  playChooseBuildingSound () {
    this.chooseBuildingRef.current?.play()
  }

  playButtonSound () {
    this.buttonClickRef.current?.play()
  }

  render () {
    return <>
            <audio ref={this.buildRef} src={'/sounds/build.mp3'}/>
            <audio ref={this.chooseBuildingRef} src={'/sounds/choose_building.mp3'}/>
            <audio ref={this.buttonClickRef} src={'/sounds/button_click.mp3'}/>
        </>
  }
}
