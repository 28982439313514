import React from 'react'
import styles from './Menu.module.scss'
import MenuGameCard from '../MenuGameCard/MenuGameCard'
import MenuButtonRed from '../MenuButtonRed/MenuButtonRed'

export default class Menu extends React.Component<{
    start: () => void,
    style: React.CSSProperties,
}, {
    showAboutPopup: boolean
}> {
  constructor (props: any) {
    super(props)

    this.state = {
      showAboutPopup: false
    }
  }

  toggleAboutPopup () {
    const popup = document.querySelector('.aboutPopupBg') as HTMLElement

    if (parseInt(popup.style.opacity)) {
      popup.style.opacity = '0'
      setTimeout(() => {
        popup.style.display = 'none'
      }, 200)
    } else {
      popup.style.display = 'block'
      setTimeout(() => {
        popup.style.opacity = '1'
      }, 200)
    }
  }

  render () {
    return <div className={styles.menu} style={this.props.style}>
            <div className={styles.row}>
                <a href={'/napromzone'} className={styles.gameCard} rel="noreferrer">
                    <MenuGameCard onClick={() => document.dispatchEvent(new Event('play_button_sound'))}
                                  buttonText={'на промзону'}
                                  imagePath={`url(${process.env.PUBLIC_URL + '/menu/game_card_1.jpg'})`}
                                  width={'100%'}
                                  height={'100%'}>
                        Комплексное развитие территорий преобразит город
                    </MenuGameCard>
                </a>

                <div className={styles.gameCard}>
                    <MenuGameCard onClick={this.props.start}
                                  buttonText={'ПОСТРОЙ КВАРТАЛ'}
                                  imagePath={`url(${process.env.PUBLIC_URL + '/menu/game_card_2.jpg'})`}
                                  width={'100%'}
                                  height={'100%'}>
                        Создайте свой сбалансированный квартал
                    </MenuGameCard>
                </div>
            </div>

            {/* <div className={styles.aboutButton}> */}
                <a onClick={() => this.toggleAboutPopup()} className={styles.aboutProject}
                >
                    <MenuButtonRed onClick={() => document.dispatchEvent(new Event('play_button_sound'))}
                                   width={'100%'} height={'100%'}>
                        Узнать о проекте
                    </MenuButtonRed>
                </a>
            {/* </div> */}

            <div onClick={() => this.toggleAboutPopup()}
                 style={{ display: this.state.showAboutPopup ? '' : 'none', opacity: '0' }}
                 className={'popupBg aboutPopupBg'}>
                <div className={'popupHint popupHintBig'}>
                    <div style={{ position: 'relative' }}>
                        <svg className={'popupHintClose'} width="17" height="17" viewBox="0 0 17 17" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.4883 8.59942L16.5526 2.53509C17.1491 1.9386 17.1491 1.04386 16.5526 0.447368C15.9561 -0.149123 15.0614 -0.149123 14.4649 0.447368L8.40058 6.5117L2.6345 0.745614C2.03801 0.149123 1.04386 0.149123 0.447368 0.745614C-0.149123 1.34211 -0.149123 2.23684 0.447368 2.83333L6.21345 8.59942L0.447368 14.3655C-0.149123 14.962 -0.149123 15.8567 0.447368 16.4532C0.745614 16.8509 1.14327 16.9503 1.54094 16.9503C1.9386 16.9503 2.33626 16.8509 2.6345 16.5526L8.40058 10.7865L14.1667 16.5526C14.4649 16.8509 14.8626 16.9503 15.2602 16.9503C15.6579 16.9503 16.0556 16.8509 16.3538 16.5526C16.9503 15.9561 16.9503 15.0614 16.3538 14.4649L10.4883 8.59942Z"
                                fill="currentColor"/>
                        </svg>
                    </div>
                    <div className={'popupHintText'}>
                        <iframe width={'100%'} height={'100%'} src={'https://iquarters.ru/'}/>
                    </div>
                </div>
            </div>
        </div>
  }
}
