import React from 'react'
import styles from './LoadingScreen.module.scss'
import Progressbar from '../Progressbar/Progressbar'

export default class LoadingScreen extends React.Component<{
    onComplete: () => void,
    style: React.CSSProperties
},
    {
        progress: number,
        backgroundImage: string
    }> {
  constructor (props: any) {
    super(props)

    this.state = {
      progress: 0,
      backgroundImage: '/menu/loading_bg.jpg'
    }
  }

  componentDidMount () {
    this.startProgress()
    this.changeBackgroundImage()
  }

  generateRandom (maxLimit = 100) {
    let rand = Math.random() * maxLimit
    rand = Math.floor(rand)
    return rand
  }

  startProgress () {
    const interval = setInterval(() => {
      const progressToBeSet = this.state.progress + this.generateRandom(15)

      this.setState({
        progress: progressToBeSet < 100 ? progressToBeSet : 100
      })

      if (this.state.progress === 100) {
        clearInterval(interval)
        this.props.onComplete()
      }
    }, 500)
  }

  changeBackgroundImage () {
    const interval = setInterval(() => {
      this.setState({
        backgroundImage: this.state.backgroundImage === '/menu/loading_bg.jpg' ? '/menu/loading_bg_2.jpg' : '/menu/loading_bg.jpg'
      })

      if (this.state.progress === 100) {
        clearInterval(interval)
      }
    }, 2500)
  }

  render () {
    return <div className={styles.LoadingScreen}
                    style={{ backgroundImage: `url(${process.env.PUBLIC_URL + this.state.backgroundImage})`, ...this.props.style }}>
            <div className={styles.loading}>{this.state.progress.toString()}%</div>
            <div className={styles.menuProgressbar}>
                <Progressbar value={this.state.progress} width={'100%'} height={'100%'}/>
            </div>
        </div>
  }
}
