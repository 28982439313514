export class TileImage {
  imagePath: string
  image: HTMLImageElement

  constructor (imagePath: string) {
    this.imagePath = imagePath
    this.image = new Image()
  }

  init () : Promise<TileImage> {
    return new Promise((resolve, reject) => {
      this.image.src = this.imagePath
      this.image.addEventListener('load', () => resolve(this))
      this.image.addEventListener('error', (err) => reject(err))
    })
  }
}

export class TileImageFactory {
  tileImagesPath: Array<string>
  tileImages: Array<TileImage>

  constructor (tileImagesPath: Array<string>) {
    this.tileImagesPath = tileImagesPath
    this.tileImages = []
    this.tileImagesPath.forEach((path) => {
      this.tileImages.push(new TileImage(path))
    })
  }

  init (): Promise<Array<TileImage>> {
    const promisesArray = [] as Array<Promise<TileImage>>
    this.tileImages.forEach((tileImage) => {
      promisesArray.push(tileImage.init())
    })

    return Promise.all(promisesArray)
  }

  getTileImageByPath (path: string) : TileImage { // | null {
    let result = null

    this.tileImages.forEach((tileImage) => {
      if (tileImage.imagePath === path) { result = tileImage }
    })

    if (!result) { throw Error('Нет такого изображения тайла: ' + path) }

    return result
  }

  getTileByFileName (fileName: string) : TileImage {
    let result = null

    this.tileImages.forEach((tileImage) => {
      if (tileImage.imagePath.includes(fileName)) { result = tileImage }
    })

    if (!result) { throw Error('Нет такого изображения тайла') }

    return result
  }
}
