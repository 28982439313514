import { Tile } from './Tile'
import { TileImage } from './TileImage'

export class GameObject extends Tile {
  offsetX: number
  offsetY: number
  onRefresh: Function

  constructor (tileImage: TileImage, posX: number,
    posY: number,
    x: number,
    y: number,
    w: number,
    h: number,
    z: number,
    offsetX: number = 0, offsetY: number = 0, onRefresh: Function = () => {}) {
    super(tileImage, posX, posY, x, y, w, h, z)

    this.offsetX = offsetX
    this.offsetY = offsetY
    this.onRefresh = onRefresh
  }
}
