import { Tile } from './Tile'
import { TileImage } from './TileImage'
import { GameObject } from './GameObject'

export class GameMap {
  mapWidth: number
  mapHeight: number
  mapOffsetX: number
  mapOffsetY: number
  tileWidth: number
  tileHeight: number

  mapArray: Array<Array<number>>
  tilesArray: Array<Array<Tile>>
  objectsArray: Array<Array<GameObject|null>>

  secretTilesQueue: Array<Tile>
  secretGameObjectsQueue: Array<GameObject>

  constructor (tileWidth: number, tileHeight: number, mapWidth: number, mapHeight: number, mapOffsetX: number, mapOffsetY: number) {
    this.tileWidth = tileWidth
    this.tileHeight = tileHeight
    this.mapWidth = mapWidth
    this.mapHeight = mapHeight
    this.mapOffsetX = mapOffsetX
    this.mapOffsetY = mapOffsetY

    this.mapArray = Array.from({ length: mapHeight }, () => Array(mapWidth).fill(null))
    this.tilesArray = Array.from({ length: mapHeight }, () => Array(mapWidth).fill(null))
    this.objectsArray = Array.from({ length: mapHeight }, () => Array(mapWidth).fill(null))

    this.secretTilesQueue = []
    this.secretGameObjectsQueue = []
  }

  addTile (tileImage: TileImage, row: number, column: number, deep: number = 0, canBeOverflown: boolean = true) {
    const dltX = column > 0 ? this.tileWidth * 0.5 : 0
    const dltY = this.tileHeight * 0.5 * column

    const tileToAdd = new Tile(tileImage, column, row, column * this.tileWidth - dltX * column + this.mapOffsetX - (row) * this.tileWidth / 2, row * this.tileHeight + dltY - (row) * this.tileHeight / 2 + this.mapOffsetY, this.tileWidth, this.tileHeight, deep, canBeOverflown)
    this.tilesArray[row][column] = tileToAdd

    this.secretTilesQueue.push(tileToAdd)

    this.secretTilesQueue = this.secretTilesQueue.sort((a, b) => {
      return a.z - b.z
    })
  }

  updateTileSize (width: number, height: number) {
    this.tileWidth = width
    this.tileHeight = height
  }

  updateMapOffset (x: number, y: number) {
    this.mapOffsetX = x
    this.mapOffsetY = y
  }

  createGameObject (image: TileImage, row: number, column: number, deep: number, width: number, height: number, offsetX: number, offsetY: number, onRefresh: Function = () => {
  }) {
    const dltX = column > 0 ? this.tileWidth * 0.5 : 0
    const dltY = this.tileHeight * 0.5 * column

    const gameObjectToAdd = new GameObject(image, column, row, column * this.tileWidth - dltX * column + this.mapOffsetX - (row) * this.tileWidth / 2, row * this.tileHeight + dltY - (row) * this.tileHeight / 2 + this.mapOffsetY, width, height, deep, offsetX, offsetY, onRefresh)

    this.objectsArray[row][column] = gameObjectToAdd

    this.secretGameObjectsQueue.push(gameObjectToAdd)

    this.secretGameObjectsQueue = this.secretGameObjectsQueue.sort((a, b) => {
      return a.z - b.z
    })
  }

  pushGameObject (gameObject: GameObject) {
    const dltX = gameObject.posX > 0 ? this.tileWidth * 0.5 : 0
    const dltY = this.tileHeight * 0.5 * gameObject.posX

    const gameObjectToAdd = new GameObject(gameObject.tileImage, gameObject.posX, gameObject.posY, gameObject.posX * this.tileWidth - dltX * gameObject.posX + this.mapOffsetX - (gameObject.posY) * this.tileWidth / 2, gameObject.posY * this.tileHeight + dltY - (gameObject.posY) * this.tileHeight / 2 + this.mapOffsetY, gameObject.w, gameObject.h, gameObject.z, gameObject.offsetX, gameObject.offsetY, gameObject.onRefresh)

    this.objectsArray[gameObject.posY][gameObject.posX] = gameObjectToAdd

    this.secretGameObjectsQueue.push(gameObjectToAdd)

    this.secretGameObjectsQueue = this.secretGameObjectsQueue.sort((a, b) => {
      return a.z - b.z
    })
  }

  updateGameObjects () {
    this.objectsArray.forEach((row) => {
      row.forEach((object) => {
        if (!object) {
          return
        }

        object.onRefresh(object, this)
      })
    })
  }

  drawTiles (ctx: CanvasRenderingContext2D) {
    this.secretTilesQueue.forEach((tile) => {
      if (!tile) {
        return
      }

      const dltX = tile.posX > 0 ? this.tileWidth * 0.5 : 0
      const dltY = this.tileHeight * 0.5 * tile.posX

      tile.x = tile.posX * this.tileWidth - dltX * tile.posX + this.mapOffsetX - (tile.posY) * this.tileWidth / 2
      tile.y = tile.posY * this.tileHeight + dltY - (tile.posY) * this.tileHeight / 2 + this.mapOffsetY
      tile.w = this.tileWidth
      tile.h = this.tileHeight
      tile.centerX = tile.x + tile.w * 0.5
      tile.centerY = tile.y + tile.h * 0.5

      ctx.drawImage(tile.tileImage.image, tile.x, tile.y, tile.w, tile.h)
    })
  }

  drawGameObjects (ctx: CanvasRenderingContext2D) {
    this.secretGameObjectsQueue.forEach((object) => {
      if (!object) {
        return
      }

      const dltX = object.posX > 0 ? this.tileWidth * 0.5 : 0
      const dltY = this.tileHeight * 0.5 * object.posX

      object.x = object.posX * this.tileWidth - dltX * object.posX + this.mapOffsetX - (object.posY) * this.tileWidth / 2
      object.y = object.posY * this.tileHeight + dltY - (object.posY) * this.tileHeight / 2 + this.mapOffsetY

      ctx.drawImage(object.tileImage.image, object.x + object.offsetX, object.y + object.offsetY, object.w, object.h)
    })
  }
}
