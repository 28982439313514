import React from 'react'
import styles from './MenuGameCard.module.scss'

export default class MenuGameCard extends React.Component<{
    width: string,
    height: string,
    imagePath: string,
    children: React.ReactNode,
    buttonText: string,
    onClick?: () => void;
}, {}> {
  render () {
    return <div onClick={this.props.onClick} className={styles.menuGameCard} style={{ width: this.props.width, height: this.props.height }}>
            <div style={{ backgroundImage: this.props.imagePath }} className={styles.menuGameCard__backgroundImage}/>
            <div className={styles.menuGameCard__textContainer}>
                <div className={styles.menuGameCard__text}>
                    {this.props.children}
                </div>

                <div className={styles.menuGameCard__button}>
                    {this.props.buttonText}
                </div>
            </div>
        </div>
  }
}
